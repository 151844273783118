import EventEmitter from 'events';

import ClearIcon from '@mui/icons-material/Clear';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import TextsmsIcon from '@mui/icons-material/Textsms';
import {
    Modal,
    Button,
    DialogActions,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    styled,
    Switch,
    AppBar,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Toolbar as MuiToolbar,
    PaletteMode,
    useMediaQuery,
    Tooltip,
    useTheme,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { format } from 'date-fns';
import { ProviderContext } from 'notistack';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { darkModeDay, darkModeNight, emphasizeLogo } from '../assets/source-data';
import { ConnectionsDialog } from '../connect/ConnectionsDialog';
import { Sync } from '../connect/sync';
import { Event } from '../timeline/event';
import { CellMode } from '../tracking/cell-mode';
import { Info } from '../tracking/Info.component';
import { clearAllEvents, importEvents, validateEventsImport } from '../utils/event-utils';
import { KEY_LEGALS_SEEN } from '../utils/locale.storage.keys';
import { get } from '../utils/storage-impl';
import { useConfig } from '../utils/use-config';

import { MaintenanceDialog } from './MaintenanceDialog';
import { ModeBar } from './ModeBar';

const REQUIRED_LEGAL_UPDATE = '2024-01-04';

const DarkModeSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `${darkModeDay}`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `${darkModeNight}`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

type ComponentProps = {
    downloadEvents: () => void;
    downloadLayout: () => void;
    onInfo: (info: string) => void;
    cursor: number;
    setCursor: (time: number) => void;
    mode: CellMode;
    setMode: (mode: CellMode) => void;
    themeAction: EventEmitter;
    messageBar: ProviderContext;
    action: EventEmitter;
    info: string;
    setInfo: (info: string) => void;
    updateNow: () => void;
    sync: Sync;
    setSync: (sync: Sync) => void;
    onEvent: (event: Event, userTriggered: boolean) => void;
};

export function Toolbar({
    downloadEvents,
    downloadLayout,
    onInfo,
    cursor,
    setCursor,
    mode,
    setMode,
    themeAction,
    messageBar,
    action,
    info,
    setInfo,
    updateNow,
    sync,
    setSync,
    onEvent,
}: ComponentProps) {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    const [themeMode, setThemeMode] = useConfig<PaletteMode>('theme-mode', 'dark');
    const [legalsSeen, setLegalsSeen] = useConfig(KEY_LEGALS_SEEN, '');
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [enterInfo, setEnterInfo] = useState(false);
    const showMaintenance = location.pathname === '/export' || location.pathname === '/import';
    const showSync = location.pathname === '/connections';
    const showLegals = location.pathname === '/legals';
    const showImprint = location.pathname === '/imprint';
    const importEventsRef = useRef<HTMLInputElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogAction, setDialogAction] = useState(() => undefined);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogText, setDialogText] = useState('');
    const [dialogOk, setDialogOk] = useState('');
    const [dialogCancel, setDialogCancel] = useState('');

    const triggerImport = () => importEventsRef.current && importEventsRef.current.click();
    useEffect(() => {
        action.on('importEvents', triggerImport);
        return () => {
            action.off('importEvents', triggerImport);
        };
    }, [action]);

    useMemo(() => {
        if (legalsSeen < REQUIRED_LEGAL_UPDATE) {
            setTimeout(() => {
                if (get(KEY_LEGALS_SEEN) < REQUIRED_LEGAL_UPDATE) {
                    navigate('/legals');
                }
            }, 3000);
        }
    }, [legalsSeen, navigate]);

    const onImportEvents = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        if (!target.files) {
            return;
        }
        const fileReader = new FileReader();
        fileReader.readAsText(target.files[0]);
        fileReader.onload = (e) => {
            if (!e.target) {
                return;
            }
            const events = e.target.result as string;
            try {
                validateEventsImport(events);
                importEvents(events, onEvent);
                messageBar.enqueueSnackbar(t('success') + ': ' + t('import.csv'), { variant: 'success' });
                updateNow();
            } catch (ex) {
                messageBar.enqueueSnackbar(t('failed') + ': ' + t('import.csv') + ' ' + '(' + ex + ')', { variant: 'warning' });
            }
            importEventsRef.current.value = null;
        };
    };

    const clearEvents = useCallback(() => {
        setDialogCancel(t('abort'));
        setDialogOk(t('ok'));
        setDialogTitle(t('confirm.clear.csv.title'));
        setDialogText(t('confirm.clear.csv.text'));
        setDialogAction(() => (ok: boolean) => {
            if (ok) {
                clearAllEvents();
                messageBar.enqueueSnackbar(t('success') + ': ' + t('clear.csv'), { variant: 'success' });
                updateNow();
            }
        });
        setDialogOpen(true);
    }, [messageBar, updateNow, t]);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" color="inherit">
            <input ref={importEventsRef} type="file" accept="text/csv" hidden onChange={onImportEvents} />
            <MuiToolbar sx={{ height: '72px' }}>
                <Tooltip arrow title={t('menu')}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        data-testid="menu"
                        aria-label={t('menu')}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <MenuIcon />
                    </IconButton>
                </Tooltip>
                <Menu
                    id="main-menu"
                    aria-labelledby="main-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        <ModeBar
                            mode={mode}
                            setMode={(newMode) => {
                                setMode(newMode);
                                handleClose();
                            }}
                            sync={sync}
                        />
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            navigate('/report');
                            handleClose();
                        }}
                    >
                        {t('reporting')}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            navigate('/export');
                            handleClose();
                        }}
                    >
                        {t('maintenance')}
                    </MenuItem>
                    <MenuItem
                        data-testid="menu.connections"
                        onClick={() => {
                            navigate('/connections');
                            handleClose();
                        }}
                    >
                        {t('synchronize')}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            navigate('/legals');
                            handleClose();
                        }}
                    >
                        {t('legals')}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            navigate('/imprint');
                            handleClose();
                        }}
                    >
                        {t('imprint')}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            const newThemeMode = themeMode === 'dark' ? 'light' : 'dark';
                            setThemeMode(newThemeMode);
                            themeAction.emit('themeChanged', newThemeMode);
                        }}
                    >
                        <DarkModeSwitch
                            checked={themeMode === 'dark'}
                            onChange={() => setThemeMode(themeMode === 'dark' ? 'light' : 'dark')}
                        ></DarkModeSwitch>{' '}
                        {t('theme')}
                    </MenuItem>
                </Menu>
                <Box sx={{ flexGrow: 1, display: 'inline-flex' }}>
                    {enterInfo ? (
                        <Info info={info} setInfo={setInfo} onInfo={onInfo} onClose={() => setEnterInfo(false)} />
                    ) : (
                        <>
                            <DateTimePicker
                                ampm={false}
                                label={t('time')}
                                format={t('datetime.format')}
                                value={new Date(cursor)}
                                onChange={(newValue) => {
                                    if (!newValue) {
                                        return;
                                    }
                                    setCursor(newValue.getTime());
                                }}
                            />
                            <Tooltip arrow title={t('info.enter')}>
                                <IconButton
                                    size="large"
                                    aria-label={t('info.enter')}
                                    aria-controls={'primary-search-account-menu-mobile'}
                                    aria-haspopup="true"
                                    data-testid="info.enter"
                                    onClick={() => setEnterInfo(true)}
                                    color="inherit"
                                >
                                    <TextsmsIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </Box>
                {!enterInfo && (
                    <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <ModeBar mode={mode} setMode={setMode} sync={sync} />
                        </Box>
                        <Tooltip arrow title={t('mode.off')}>
                            <IconButton
                                size="large"
                                aria-label={t('mode.off')}
                                aria-controls={'primary-mode.off-mobile'}
                                data-testid="mode.off"
                                aria-haspopup="true"
                                onClick={() => setMode(CellMode.OFF)}
                                color="inherit"
                            >
                                <LogoutIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </MuiToolbar>

            <Modal
                open={showLegals}
                onClose={() => {
                    setLegalsSeen(format(new Date(), 'yyyy-MM-dd'));
                    navigate('/legals');
                }}
                aria-labelledby="legals"
                aria-describedby="legals-description"
            >
                <Box
                    sx={{
                        position: 'absolute' as const,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '80vw', md: 400 },
                        overflowY: 'auto',
                        maxHeight: '50vh',
                        bgcolor: 'background.paper',
                        color: 'primary.main',
                        border: '2px solid #000',
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                    }}
                >
                    <Tooltip arrow title={t('close')}>
                        <IconButton
                            aria-label={t('close')}
                            data-testid="legals.close"
                            onClick={() => {
                                setLegalsSeen(format(new Date(), 'yyyy-MM-dd'));
                                navigate('/');
                            }}
                            edge="end"
                            style={{ margin: '1em 0 0 auto', display: 'block' }}
                        >
                            <ClearIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <h2>{t('legals.title')}</h2>
                    <p>
                        {t('legals.text0')}
                        <a href={'https://www.emphasize.de/' + (i18n.language === 'de' ? 'de' : 'en') + '/api.html'} target="_blank">
                            {t('legals.api')}
                        </a>
                        {t('legals.text1')}
                    </p>
                    <a
                        href={'https://www.emphasize-it.de/' + (i18n.language === 'de' ? 'de' : 'en') + '/contact.html'}
                        target="_blank"
                        style={{ textAlign: 'right', display: 'block' }}
                    >
                        {t('legals.title')}
                    </a>
                    <Button
                        onClick={() => {
                            setLegalsSeen(format(new Date(), 'yyyy-MM-dd'));
                            navigate('/');
                        }}
                        style={{ margin: '1em 0 0 auto', display: 'block' }}
                    >
                        {t('ok')}
                    </Button>
                </Box>
            </Modal>

            <Modal open={showImprint} onClose={() => navigate('/')} aria-labelledby="imprint" aria-describedby="imprint-description">
                <Box
                    sx={{
                        position: 'absolute' as const,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '80vw', md: 400 },
                        overflowY: 'auto',
                        maxHeight: '50vh',
                        bgcolor: 'background.paper',
                        color: 'primary.main',
                        border: '2px solid #000',
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                    }}
                >
                    <Tooltip arrow title={t('close')}>
                        <IconButton
                            aria-label={t('close')}
                            onClick={() => navigate('/')}
                            edge="end"
                            style={{ margin: '1em 0 0 auto', display: 'block' }}
                        >
                            <ClearIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <img style={{ margin: '0 auto', display: 'block' }} src={emphasizeLogo}></img>
                    <h2>{t('imprint.title')}</h2>
                    <p>{t('imprint.text')}</p>
                    <a
                        href={'https://www.emphasize-it.de/' + (i18n.language === 'de' ? 'de' : 'en') + '/contact.html'}
                        target="_blank"
                        style={{ textAlign: 'right', display: 'block' }}
                    >
                        {t('imprint.title')}
                    </a>
                    <Button onClick={() => navigate('/')} style={{ margin: '1em 0 0 auto', display: 'block' }}>
                        {t('ok')}
                    </Button>
                </Box>
            </Modal>

            <Dialog
                fullScreen={fullScreen}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogText}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setDialogOpen(false)}>
                        {dialogCancel}
                    </Button>
                    <Button
                        onClick={() => {
                            setDialogOpen(false);
                            dialogAction(true);
                        }}
                    >
                        {dialogOk}
                    </Button>
                </DialogActions>
            </Dialog>

            <MaintenanceDialog
                open={showMaintenance}
                onClose={() => navigate('/')}
                downloadEvents={downloadEvents}
                downloadLayout={downloadLayout}
                clearEvents={clearEvents}
                action={action}
            ></MaintenanceDialog>

            <ConnectionsDialog
                open={showSync}
                onClose={() => navigate('/')}
                sync={sync}
                setSync={setSync}
                setMode={setMode}
            ></ConnectionsDialog>
        </AppBar>
    );
}
